




























import { Credentials } from '@/entities/auth/Credentials'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class LoginEditor extends Vue {
    @Prop() value!: Credentials
    @Prop() disabled?: boolean
}
