











import { Vue, Component, Prop } from 'vue-property-decorator'
import { createEmptyCredentials } from '@/entities/mappers/Factory'
import store from '@/store'
import LoginEditor from './LoginEditor.vue'

@Component({
    components: {
        LoginEditor,
    },
})
export default class LoginForm extends Vue {
    @Prop() disabled?: boolean

    credentials = createEmptyCredentials()

    login() {
        store.dispatch.auth.login(this.credentials)
    }
}
