






















import { Vue, Component } from 'vue-property-decorator'
import BackendConnector from '@/components/login/BackendConnector.vue'
import LoginForm from '@/components/login/LoginForm.vue'
import store from '@/store'
import RawmotionLogo from '@/components/menu/RawmotionLogo.vue'
import UserEditorModal from '@/components/user/UserEditorModal.vue'

@Component({
    components: {
        BackendConnector,
        LoginForm,
        RawmotionLogo,
        UserEditorModal,
    },
})
export default class LoginView extends Vue {
    get isConnectedToBackend() {
        return store.getters.auth.isConnectedToBackend
    }

    get backendIsVirgin() {
        return store.getters.auth.backendIsVirgin
    }

    showUserModal = false
    openUserModal() {
        this.showUserModal = true
    }
}
