



















import { trsl } from '@/i18n/lang'
import store from '@/store'
import { Vue, Component, Watch } from 'vue-property-decorator'

@Component
export default class BackendConnector extends Vue {
    get isConnected() {
        return store.getters.auth.isConnectedToBackend
    }

    get baseUrl() {
        return store.getters.auth.baseUrl
    }

    @Watch('baseUrl')
    updateEditUrl() {
        if (this.baseUrl) {
            this.editUrl = this.baseUrl
        }
    }

    mounted() {
        this.updateEditUrl()
    }

    editUrl = ''

    get buttonText() {
        const keyword = this.isConnected ? 'disconnect' : 'connect'
        return trsl(`input.action.${keyword}`)
    }

    toggleConnection() {
        if (this.isConnected) {
            store.dispatch.auth.disconnectFromBackend().then(() => {
                store.commit.message.showToast({
                    textKey: 'success.disconnect-from-backend',
                    variant: 'success',
                })
            })
        } else {
            store.dispatch.auth
                .connectToBackend(this.editUrl)
                .then(() => {
                    store.commit.message.showToast({
                        textKey: 'success.connect-to-backend',
                        variant: 'success',
                    })
                })
                .catch(() => {
                    store.commit.message.showToast({
                        textKey: 'error.connect-to-backend',
                        variant: 'danger',
                    })
                })
        }
    }
}
